import React, { useState, useEffect } from 'react';

const NavInterviews = () => {
  // Retrieve last active section from localStorage (default to "1")
  const [isActiveIndex, setIsActiveIndex] = useState(localStorage.getItem('activeInterviewsSection') || "1");

  const toggleActiveClass = (index) => {
    setIsActiveIndex(index);
    localStorage.setItem('activeInterviewsSection', index); // Store in localStorage
    document.getElementById(`section-${index}`)?.scrollIntoView({ behavior: 'smooth' });
  };

  // Function to observe sections on scroll
  useEffect(() => {
    const sections = document.querySelectorAll('.content-section'); // Target all sections

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const sectionId = entry.target.id.replace('section-', '');
            setIsActiveIndex(sectionId);
            localStorage.setItem('activeInterviewsSection', sectionId); // Store new section
          }
        });
      },
      { threshold: 0.6 } // Trigger when 60% of section is visible
    );

    sections.forEach((section) => observer.observe(section));

    return () => {
      sections.forEach((section) => observer.unobserve(section));
    };
  }, []);

  // Scroll to the last active section when the component mounts
  useEffect(() => {
    const savedIndex = localStorage.getItem('activeInterviewsSection');
    if (savedIndex) {
      document.getElementById(`section-${savedIndex}`)?.scrollIntoView({ behavior: 'smooth' });
    }
  }, []);

  return (
    <div className="interviews-nav-section">
      <nav className="interviews-nav flex-group flex-column mw10">
        <h4>Past Experience</h4>
        <a 
          className={`${isActiveIndex === "1" ? 'active' : ''} flex-group flex-center`} 
          onClick={(e) => { e.preventDefault(); toggleActiveClass("1"); }} 
          href="#section-1"
        >
          15 years experience
        </a>

        <h4 className="margin-top-large">Design Process</h4>
        <a 
          className={`${isActiveIndex === "2" ? 'active' : ''} flex-group flex-center`} 
          onClick={(e) => { e.preventDefault(); toggleActiveClass("2"); }} 
          href="#section-2"
        >
          1) Initial Phase
        </a>
        <a 
          className={`${isActiveIndex === "3" ? 'active' : ''} flex-group flex-center`} 
          onClick={(e) => { e.preventDefault(); toggleActiveClass("3"); }} 
          href="#section-3"
        >
          2) Research Phase
        </a>
        <a 
          className={`${isActiveIndex === "4" ? 'active' : ''} flex-group flex-center`} 
          onClick={(e) => { e.preventDefault(); toggleActiveClass("4"); }} 
          href="#section-4"
        >
          3) Problem Solving Phase
        </a>
        <a 
          className={`${isActiveIndex === "5" ? 'active' : ''} flex-group flex-center`} 
          onClick={(e) => { e.preventDefault(); toggleActiveClass("5"); }} 
          href="#section-5"
        >
          4) Ideation & Concept Phase
        </a>
        <a 
          className={`${isActiveIndex === "6" ? 'active' : ''} flex-group flex-center`} 
          onClick={(e) => { e.preventDefault(); toggleActiveClass("6"); }} 
          href="#section-6"
        >
          5) High-Fidelity Design Phase
        </a>
        <a 
          className={`${isActiveIndex === "7" ? 'active' : ''} flex-group flex-center`} 
          onClick={(e) => { e.preventDefault(); toggleActiveClass("7"); }} 
          href="#section-7"
        >
          6) Hand-off Phase
        </a>
        <a 
          className={`${isActiveIndex === "8" ? 'active' : ''} flex-group flex-center`} 
          onClick={(e) => { e.preventDefault(); toggleActiveClass("8"); }} 
          href="#section-8"
        >
          7) Testing Phase
        </a>
        <a 
          className={`${isActiveIndex === "9" ? 'active' : ''} flex-group flex-center`} 
          onClick={(e) => { e.preventDefault(); toggleActiveClass("9"); }} 
          href="#section-9"
        >
          8) Post Launch Phase
        </a>

        <h4 className="margin-top-large">Questions</h4>
        <a 
          className={`${isActiveIndex === "10" ? 'active' : ''} flex-group flex-center`} 
          onClick={(e) => { e.preventDefault(); toggleActiveClass("10"); }} 
          href="#section-10"
        >
          My Questions
        </a>
        <h4 className="margin-top-large">Questions they may ask</h4>
        <a 
          className={`${isActiveIndex === "11" ? 'active' : ''} flex-group flex-center`} 
          onClick={(e) => { e.preventDefault(); toggleActiveClass("11"); }} 
          href="#section-11"
        >
          Team disagreements
        </a>
        <a 
          className={`${isActiveIndex === "12" ? 'active' : ''} flex-group flex-center`} 
          onClick={(e) => { e.preventDefault(); toggleActiveClass("12"); }} 
          href="#section-12"
        >
          Deadlines/requirement changes
        </a>
        <a 
          className={`${isActiveIndex === "13" ? 'active' : ''} flex-group flex-center`} 
          onClick={(e) => { e.preventDefault(); toggleActiveClass("13"); }} 
          href="#section-13"
        >
          What are your strengths <br/>& weaknesses
        </a>
        <a 
          className={`${isActiveIndex === "14" ? 'active' : ''} flex-group flex-center`} 
          onClick={(e) => { e.preventDefault(); toggleActiveClass("14"); }} 
          href="#section-14"
        >
          Tell me about a time you had <br/>to manage conflicting <br/>stakeholder expectations.
        </a>
        <a 
          className={`${isActiveIndex === "15" ? 'active' : ''} flex-group flex-center`} 
          onClick={(e) => { e.preventDefault(); toggleActiveClass("15"); }} 
          href="#section-15"
        >
          Describe a project where you<br/>had a significant impact
        </a>
        <a 
          className={`${isActiveIndex === "16" ? 'active' : ''} flex-group flex-center`} 
          onClick={(e) => { e.preventDefault(); toggleActiveClass("16"); }} 
          href="#section-16"
        >
          Tell me about a time when you<br/>faced a major challenge
        </a>
        <a 
          className={`${isActiveIndex === "17" ? 'active' : ''} flex-group flex-center`} 
          onClick={(e) => { e.preventDefault(); toggleActiveClass("17"); }} 
          href="#section-17"
        >
          Share an experience working <br/>with a cross-functional team
        </a>
      </nav>
    </div>
  );
};

export default NavInterviews;
