import React from 'react';
import ReactBody from 'react-body';
import { StickyContainer } from 'react-sticky';
import { Element } from 'react-scroll';
import NavInterviews from '../header/NavInterviews';
import ActiveSection from '../../scripts/active-section';

const Interviews = () => {
  var menuRef = React.createRef();

  return (
    <section className="interviews-content">
      <ReactBody className="interviews-page" />
      <NavInterviews ref={menuRef} />
      <section className='flex-group flex-align-center'>
      <StickyContainer>
        <Element className='element'>
          <section id="section-1" className='content-section flex-group flex-center'>
            <ActiveSection index="1" refToMenu={menuRef}>
              <section className='script flex-group flex-center flex-column'>
                <h2>15 Years Experience</h2>
                <p>
                  "So, I have over 15 years of experience in Product Design, UX/UI, and Front-End Dev, 
                  working across a few different sectors. My journey began at design agencies, including FullSix 
                  and Ogilvy One, where I worked on projects for various clients. This helped me develop a 
                  solid foundation in design and conceptualisation.
                </p>
                <p>
                  "From there, I moved client-side working for Pfizer, where I focused on creating their intranet 
                  for medical systems. I managed a small team there but it was very much a hands-on role."
                </p>
                <p>
                  "I then moved into entertainment and broadcasting, to design the new TopGear website, 
                  Cartoon Network, Boomerang and CNN."
                </p>
                <p>
                  "Later, I worked in enterprise software, Universal Music and IBM. Most recently Anglo 
                  American, where I lead the design of their commodity trading platform. My role involves 
                  creating intuitive applications for traders to manage transactions. 
                  Additionally, I oversee the UX design, branding, and development of a comprehensive 
                  design system called the 'Design Language' which I built from scratch in both Figma and 
                  Angular. The component library has become the foundation for all our Anglo trading apps 
                  and atm I support roughly 120 devs to ensure the components are implemented correclty."
                </p>
                <p>
                  "I've got quite a few years experience working in agile teams, contributing to 
                  sprints and iterative design processes. I have experince of working in both Azure and AWS."
                </p>
                <p>
                  "I've used quite a few different design tools over the years but my preference is Figma. 
                  I particularly like the Design Tokens as it just makes theming more consistent."
                </p>
              </section>
            </ActiveSection>
          </section>
        </Element>
        <Element className='element'>
          <section id="section-2" className='content-section flex-group flex-center'>
          <ActiveSection index="2" refToMenu={menuRef}>
            <section className='script flex-group flex-center flex-column'>
              <h2>Initial Phase</h2>
              <p>
                "We usually have a kick-off session with the stakeholders to clarify the project scope 
                and user needs. Sometimes this is with the BA, other times with senior management."
              </p>
              <h3>Example</h3>
              <p className='example'>
                "So just as an example, when working on the StudioHub project at Universal Music, I started by meeting with 
                all the labels to understand their challenges—like managing contributors and tracks—and 
                aligning on goals like improving collaboration and reducing false rights claims which was an issue they 
                needed to address at the time. Each label has a slightly different way of doing things, so they needed 
                the ability to personalise their space."
              </p>
              </section>
            </ActiveSection>
          </section>
        </Element>
        <Element className='element'>
          <section id="section-3" className='content-section flex-group flex-center'>
          <ActiveSection index="3" refToMenu={menuRef}>
            <section className='script flex-group flex-center flex-column'>
              <h2>Research Phase</h2>
              <p>
                "Interviews, and competitor analysis to gather data and define user personas.
                This will usually include brainstorming sessions, stakeholder workshops,
                lots of user journey mappings on WhiteBoards (Navigation & Workflows) that kind of thing.
              </p>
              <h3>Example</h3>
              <p className='example'>
                "At Anglo, last year we initiated a major onboarding project. I conducted in-depth interviews with 
                onboarding managers to uncover pain points in the SKYC workflow. This research allowed us to 
                develop detailed personas and map out their core needs. The process was complex, as there were 
                three separate systems for different phases, all of which needed to integrate seamlessly."
              </p>
              </section>
            </ActiveSection>
          </section>
        </Element>
        <Element className='element'>
          <section id="section-4" className='content-section flex-group flex-center'>
            <ActiveSection index="4" refToMenu={menuRef}>
              <section className='script flex-group flex-center flex-column'>
                <h2>Problem Solving Phase</h2>
                <p>
                  "In this phase, we take all the insights gathered during the research phase and 
                  dive into solving the challenges identified. The goal is to create solutions 
                  that are both innovative and practical, addressing the specific user needs while 
                  streamlining the overall process."
                </p>
                <p>
                  "One of my favourite examples of this was **Studio Hub** for Universal Music. It was a 
                  greenfield project, and I joined right at the start, giving me the opportunity to 
                  lead the design and front-end dev of four key apps: **StudioHub**, **RightsHub**, 
                  **Archive Workspace**, and **The SupplyChain**. These apps streamlined the entire 
                  production process, from the initial studio recording through to global distribution."
                </p>

                <p>
                  "Each app addressed a unique challenge: **StudioHub** simplified album creation and 
                  contributor management; **RightsHub** ensured accurate rights and payments; **Archive Workspace** 
                  made digital assets easy to catalog and retrieve; and **SupplyChain** managed output to the 
                  streaming services such as Apple Music and Spotify."
                </p> 
                <p>
                  "A key challenge was how to manage the entire production process efficiently. Initially, this was all 
                  done manually, often with forms and inefficient processes. To address this, I began mapping 
                  the end-to-end workflows, identifying pain points through interviews with the Record Labels 
                  A&R Admin, legal, and supply chain teams. This research allowed me to design each app 
                  to address its specific needs."
                </p>
              </section>
            </ActiveSection>
          </section>
        </Element>
        <Element className='element'>
          <section id="section-5" className='content-section flex-group flex-center'>
          <ActiveSection index="5" refToMenu={menuRef}>
            <section className='script flex-group flex-center flex-column'>
              <h2>Ideation & Concept Phase</h2>
              <p>
                "So the next step is to develop wireframes based on findings, sketches, and low-fidelity prototypes 
                to explore solutions. Collaborate with the team for feedback. You don’t want 
                to spend too much time on aesthetics at this point, it’s more about 
                thrashing out ideas"
              </p>
              <h3>Example:</h3>
              <p className='example'>
                "A recent project at Anglo was the Security Model Project (AMP) which is a permissions based App 
                where you can assign users with different roles and responsibilities, there's also an approval process involved with that. 
                For this I prodiced several workflows and testing sessions, to fine-tune the final solution. 
                It's a widely used project across the organisation"
              </p>
              </section>
            </ActiveSection>
          </section>
        </Element>
        <Element className='element'>
          <section id="section-6" className='content-section flex-group flex-center'>
          <ActiveSection index="6" refToMenu={menuRef}>
            <section className='script flex-group flex-center flex-column'>
              <h2>High-Fidelity Design Phase</h2>
              <p>
                Following on from feedback sessions and once we reached an agreement, I'll then go on 
                to create the polished detailed designs and interactive prototypes in Figma, ensuring 
                consistency with the design system.
              </p>
              <h3>Example</h3>
              <p className='example'>
                "After finalising the workflows, I would create high-fidelity designs in Figma, aligning 
                them with the guidelines (Some of thse guidelines I would have created myself for 
                example here at Anglo but also at UMG). I also used Figma's prototyping features to 
                simulate user flows for usability testing."
               </p>
               <p>
                 Following on from this, when we have our fully fledged Design System in place, I then 
                 go on to create each component Angular along with all the neccessary documentation.
               </p>
              </section>
            </ActiveSection>
          </section>
        </Element>
        <Element className='element'>
          <section id="section-7" className='content-section flex-group flex-center'>
          <ActiveSection index="7" refToMenu={menuRef}>
            <section className='script flex-group flex-center flex-column'>
              <h2>Hand-off Phase</h2>
                <p>
                  At this point we share the detailed specs with developers, specify which components they 
                  need to use, set up our User Stories in Azure. This part is mostly done by the project BA. 
                  It's also around this point where I'll work with the back-end devs 
                  in building the front-end for them. so my tickets will be on the board as well. 
                </p>
              </section>
            </ActiveSection>
          </section>
        </Element>
        <Element className='element'>
          <section id="section-8" className='content-section flex-group flex-center'>
          <ActiveSection index="8" refToMenu={menuRef}>
            <section className='script flex-group flex-center flex-column'>
              <h2>Testing Phase</h2>
                <p>
                  Start usability tests with real users, gather feedback, and iterate on the design to address 
                  any issues. This invloves a combination of:<br/>
                </p>
                <p>
                  <strong>A/B Testing: </strong><br/>
                  Comparing different design versions to determine which performs better in terms of 
                  usability and user satisfaction.<br/>

                  <strong>Task-Based Testing:</strong><br/>
                  Asking users to perform specific tasks (like navigating through workflows or 
                  distributing content) to assess the ease of use.<br/>

                  <strong>Contextual Inquiry: </strong><br/>
                  Observing users as they engage with the project in their actual work environment to gain deeper 
                  insights into their workflow.<br/>

                  <strong>Heuristic Evaluation: </strong><br/>
                  Heuristic Evaluation: Applying usability principles and heuristics to identify design flaws, 
                  improve user interactions, and enhance the overall user experience. We sometimes use usability 
                  experts for this part. We used ‘User Vision’ for our Onboarding KYC project.
                </p>
                <p>
                  As a team we also run regular UX Reviews. These reviews include usability and accessability 
                  testing with tools like Selenium and Wave, but also we run our apps through 
                  the W3C and DDA Compliance checks. Mention Snagit and capturing issues and adding these to the ticket in Azure.
                </p>
                  <h3>Example:</h3>
                <p className='example'>
                  "We ran usability tests with label managers and engineers, which revealed issues like unclear navigation labels. 
                  I iterated on the designs to address these and re-tested to ensure the changes resolved their concerns."
                </p>
              </section>
            </ActiveSection>
          </section>
        </Element>
        <Element className='element'>
          <section id="section-9" className='content-section flex-group flex-center'>
          <ActiveSection index="9" refToMenu={menuRef}>
            <section className='script flex-group flex-center flex-column'>
              <h2>Delivery & Post Launch Phase</h2>
                <p>
                  Monitor the product’s performance post-launch, gather feedback, and plan iterations for future improvements.
                </p>
                <h3>Example:</h3>
                <p className='example'>
                  "After launching StudioHub, we tracked user adoption and collected feedback from labels, which informed 
                  updates like simplifying the onboarding process for new contributors, which then goes into our Sprints as 
                  part of our CI/CD processes. Have experience in both Azure and AWS."
                </p>
              </section>
            </ActiveSection>
          </section>
        </Element>
        <Element className='element'>
          <section id="section-10" className='content-section flex-group flex-center'>
          <ActiveSection index="10" refToMenu={menuRef}>
            <section className='script flex-group flex-center flex-column'>
              <h2>Questions</h2>
                <p>
                  "How does the company see itself evolving over the next three 
                  to five years, and what challenges are you most focused on solving?"
                </p>
                <p>
                  "How do designers and developers collaborate during the product lifecycle? 
                  Are there any particular tools or processes that have worked especially well?"
                </p>
                <p>
                  "Are there existing design systems or component libraries in place, and what 
                  are the main challenges in maintaining or scaling them?"
                </p>
                <p>
                  "What metrics do you prioritise when evaluating the success of a product or 
                  feature, and how are these communicated to the team?"
                </p>
                <p>
                  "What’s the biggest challenge the team is currently facing where someone 
                  with my background could add immediate value?"
                </p>
              </section>
            </ActiveSection>
          </section>
        </Element>
        <Element className='element'>
          <section id="section-11" className='content-section flex-group flex-center'>
            <ActiveSection index="11" refToMenu={menuRef}>
              <section className='script flex-group flex-center flex-column'>
                <h2>
                  How do you handle design <br/>conflicts or disagreements within the team?
                </h2>
                <p>
                  "Answer: In my experience, design conflicts often arise when different team 
                  members have varying perspectives or priorities. When this happens, 
                  I always focus on maintaining an open and collaborative environment. 
                  I approach these situations by actively listening to all viewpoints, 
                  ensuring everyone feels heard."
                </p>
                <p>
                  "For example, during the design process for **StudioHub**, we faced a 
                  disagreement on the interface design between two teams—one focused on 
                  user experience and the other on functionality. 
                  So to resolve this, we conducted several joint workshops where we mapped 
                  out each perspective and created prototypes to test the user flows. 
                  Then, we were able to identify areas where both sides could 
                  compromise."
                </p>
              </section>
            </ActiveSection>
          </section>
        </Element>
        <Element className='element'>
          <section id="section-12" className='content-section flex-group flex-center'>
            <ActiveSection index="12" refToMenu={menuRef}>
              <section className='script flex-group flex-center flex-column'>
                <h2>
                  How do you deal with tight deadlines<br/>
                  or changing project requirements?
                </h2>
                <p>
                  "Answer: I focus on maintaining flexibility and clear communication 
                  with all stakeholders. I’ve worked in environments where deadlines are 
                  tight, and requirements can change rapidly. In these situations, I 
                  prioritise MVP and features based on user impact within the given time frame."
                </p>
              </section>
            </ActiveSection>
          </section>
        </Element>
        <Element className='element'>
          <section id="section-13" className='content-section flex-group flex-center'>
            <ActiveSection index="13" refToMenu={menuRef}>
              <section className='script flex-group flex-center flex-column'>
                <h2>
                  What are your strengths and weaknesses?
                </h2>
                <strong>Strengths</strong>
                <p className='padding-top-0'>
                  I’d say one of my key strengths is my ability to bridge the gap between UX 
                  and development. I enjoy making things both look great and work 
                  seamlessly. I also really enjoy supporting others—whether it’s helping developers understand 
                  design principles or collaborating with stakeholders to refine user flows. 
                  At Anglo American, for example, I built a design system that made life easier 
                  for both designers and developers, and I found a lot of value in guiding the 
                  team through its adoption.
                </p>
                <strong>Weaknesses</strong>
                <p className='padding-top-0'>
                  At times, I can get too hands-on because I enjoy both design and development. 
                  I’ve learned that stepping back and trusting others to take ownership leads to 
                  better collaboration and efficiency. Lately, I’ve been working on improving 
                  how I delegate and provide support without micromanaging. This is something I've 
                  discussed with the business owner on how to free up some of my time.
                </p>
              </section>
            </ActiveSection>
          </section>
        </Element>
        <Element className='element'>
          <section id="section-14" className='content-section flex-group flex-center'>
            <ActiveSection index="14" refToMenu={menuRef}>
              <section className='script flex-group flex-center flex-column'>
                <h2>
                  Tell me about a time you had to manage <br/>conflicting stakeholder expectations.
                </h2>
                <h3>Example Answer (STAR Method):</h3>
                <strong>Situation</strong>
                <p className='padding-top-0'>
                  At Universal Music, I was leading the UX/UI design of a platform 
                  used by multiple record labels (e.g., Capitol Records, Def Jam, Interscope) 
                  to manage music production and distribution. Each label had its own distinct 
                  workflows and branding guidelines, which created conflicting requirements 
                  for the system’s design.
                </p>
                <strong>Task</strong>
                <p className='padding-top-0'>
                  I needed to create a unified system that worked for all labels while 
                  respecting their unique needs, ensuring ease of use while 
                  maintaining brand identity.
                </p>
                <strong>Action</strong>
                <p className='padding-top-0'>
                    1) Conducted user research and workshops with each label to understand their specific workflows, pain points, and branding needs.<br/>
                    2) Identified common functionalities that all labels required and unique elements that varied between them.<br/>
                    3) Introduced personalization features, allowing each label to have a tailored UI theme and workflow while keeping the core system consistent.<br/>
                    4) Worked closely with developers and product managers to ensure this approach was scalable and technically feasible.
                </p>
                <strong>Result</strong>
                <p className='padding-top-0'>
                    1) The personalized UI approach improved adoption rates across all labels and reduced resistance to change.<br/>
                    2) The system successfully streamlined music production and distribution, cutting down administrative work by 30%.<br/>
                    3) Stakeholders from all labels were satisfied as they felt heard and saw their specific needs reflected in the final product.<br/>
                </p>
              </section>
            </ActiveSection>
          </section>
        </Element>
        <Element className='element'>
          <section id="section-15" className='content-section flex-group flex-center'>
            <ActiveSection index="15" refToMenu={menuRef}>
              <section className='script flex-group flex-center flex-column'>
                <h2>
                  Describe a project where your work had a <br/>`significant impact on the business or users?
                </h2>
                <h3>Example Answer (STAR Method):</h3>
                <strong>Situation</strong>
                <p className='padding-top-0'>
                  At Universal Music, I designed the StudioHub platform, which managed 
                  tracks, contributors and metadata for global distribution.
                </p>
                <strong>Task</strong>
                <p className='padding-top-0'>
                  The existing manual process was inefficient, leading to errors in rights 
                  management and false claims. My task was to streamline the process with an intuitive digital tool.
                </p>
                <strong>Action</strong>
                <p className='padding-top-0'>
                  I conducted user research with music producers and rights managers to understand their 
                  workflows. Using Figma and Adobe XD, I created a prototype that automated key tasks 
                  and provided real-time tracking of contributors and rights.
                </p>
                <strong>Result</strong>
                <p className='padding-top-0'>
                  The platform significantly reduced administrative overhead, cutting processing time by 
                  40% and preventing revenue loss due to rights mismanagement.
                </p>
                <h3 className='border-red padding-small'>Also mention Design Language</h3>
              </section>
            </ActiveSection>
          </section>
        </Element>
        <Element className='element'>
          <section id="section-16" className='content-section flex-group flex-center'>
            <ActiveSection index="16" refToMenu={menuRef}>
              <section className='script flex-group flex-center flex-column'>
                <h2>
                  Tell me about a time you faced a major <br/>challenge in a project. How did you overcome it?
                </h2>
                <h3>Example Answer (STAR Method):</h3>
                <strong>Situation</strong>
                <p className='padding-top-0'>
                  At Anglo American, I was leading the UX/UI design for a multi-app onboarding platform that 
                  handled several different processes — including KYC screening, remediation, metadata input, 
                  customer approvals, and a customer-facing portal.
                </p>
                <strong>Task</strong>
                <p className='padding-top-0'>
                  The challenge was that these onboarding workflows were split across separate apps, each owned 
                  by different teams, stakeholders, and subject matter experts — many of whom had conflicting 
                  opinions and ways of working. There was no unified design direction, and feedback often contradicted itself.
                </p>
                <strong>Action</strong>
                <strong>I tackled this by introducing a more structured design and feedback process:</strong>
                <p className='padding-top-0'>
                    1) I mapped the entire onboarding journey end-to-end across all apps to identify overlaps, gaps, and dependencies.<br/>
                    2) I set up cross-functional workshops to align stakeholders, clarify ownership, and establish shared design goals.<br/>
                    3) Introduced a design language system to unify UI components and interactions across the apps — helping reduce inconsistencies and creating a more seamless experience for end users.<br/>
                    4) I also created interactive prototypes that visualised how the apps connected, which helped stakeholders see the big picture and understand user flows more clearly.
                </p>
                <strong>Result</strong>
                <p className='padding-top-0'>
                  This approach significantly improved alignment between teams. We reduced the number of conflicting feedback loops, and the design system streamlined both the UX and the developer handover process.
                  The onboarding experience became more intuitive and consistent — and internal feedback from stakeholders and users was overwhelmingly positive. Ultimately, it helped accelerate delivery timelines by removing duplicated efforts and creating a shared product vision.
                </p>
              </section>
            </ActiveSection>
          </section>
        </Element>
        <Element className='element'>
          <section id="section-17" className='content-section flex-group flex-center'>
            <ActiveSection index="17" refToMenu={menuRef}>
              <section className='script flex-group flex-center flex-column'>
                <h2>
                  Can you share an experience working with a cross-functional <br/>team? (Also refer to previous question)
                </h2>
                <h3>Example Answer (STAR Method):</h3>
                <strong>Situation</strong>
                <p className='padding-top-0'>
                  At IBM, I was part of a team designing a cloud-based SaaS product, working 
                  closely with developers, product managers, and QA engineers.
                </p>
                <strong>Task</strong>
                <p className='padding-top-0'>
                  My role was to bridge the gap between design and development, ensuring 
                  the user interface aligned with both business goals and technical feasibility.
                </p>
                <strong>Action</strong>
                <p className='padding-top-0'>
                  implemented design tokens in Figma to maintain consistency across different 
                  platforms and worked with engineers to integrate a scalable design system. 
                  I also facilitated weekly stand-ups to align on priorities.
                </p>
                <strong>Result</strong>
                <p className='padding-top-0'>
                  This streamlined collaboration reduced design handoff errors 
                  by 50% and accelerated the development cycle.
                </p>
              </section>
            </ActiveSection>
          </section>
        </Element>
      </StickyContainer>
      </section>
    </section>
  );
};

export default Interviews;